$path-img-main: '../img/';

.dg-question {
  &__overlay {
    background-color: #c5c5c5;
    overflow-y: auto !important;
    @media (min-width: 769px) {
      padding: 5vh 5vw;
    }
    @media (max-width: 768px) {
      padding: 10px;
    }
    & form {
      min-height: 100%;
    }
  }
}

.dg-question-wrapper {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 10vh);
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 5px 20px rgba(0,0,0,.06);
  &__header {
    display: flex;
    border-radius: 4px 4px 0 0;
    background-image: linear-gradient(to bottom, #0076a9, #00a79f);
    color: white;
    @media (min-width: 769px) {
      margin-bottom: 30px;
      padding: 20px 30px;
    }
    @media (max-width: 768px) {
      margin-bottom: 10px;
      padding: 10px;
    }
    &--main {
      margin-right: auto;
      font-size: 14px;
      & .dg-marked {

      }
    }
    &--timer {
      margin: 0 10px 0 0;
      padding-left: 26px;
      position: relative;
      & .dg-clock {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        fill: white;
        stroke: white;
        stroke-width: 0.5px;
      }
    }
    &--close {
      margin-left: 20px;
    }
  }
  &__quest {
    border-bottom: 1px solid #50957c;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #68b300;
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      box-shadow: inset 0 2px 10px rgba(0,0,0,0.3);
    }
    @media (min-width: 769px) {
      margin-bottom: 30px;
      padding: 0 30px 30px;
      font-size: 20px;
      font-weight: 600;
    }
    @media (max-width: 768px) {
      margin-bottom: 10px;
      padding: 0 10px 10px;
    }
    & p {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 600;
      line-height: 1.2;
      word-break: break-word;
      @media (max-width: 768px) {
        font-size: 16px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &__body {
    @media (min-width: 769px) {
      //margin-right: 4px;
      //overflow-y: auto;
      //max-height: 55vh;
      //&::-webkit-scrollbar {
      //  width: 8px;
      //}
      //&::-webkit-scrollbar-thumb {
      //  border-radius: 4px;
      //  background-image: linear-gradient(to left, #68b300,#adde09);
      //}
      //&::-webkit-scrollbar-track {
      //  border-radius: 4px;
      //  box-shadow: inset 0 2px 10px rgba(0,0,0,0.3);
      //}

      margin-bottom: 30px;
      padding: 0 26px 0 30px;
    }
    @media (max-width: 768px) {
      margin-bottom: 10px;
      padding: 0 6px 0 10px;
    }
    & label {
      display: inline-block;
      margin-bottom: 20px;
      font-size: 16px;
      position: relative;
      cursor: pointer;
      & p {
        font-size: 16px;
      }
    }
    & input[type = checkbox],
    & input[type = radio] {
      display: none;
      & ~ label {
        padding-left: 20px;
        &::before {
          content: '';
          position: absolute;
        }
      }
      &:checked ~ label {
        color: green;
      }
    }

    & input[type = checkbox] {
      & ~ label {
        &::before {
          left: 0;
          top: 5px;
          width: 12px;
          height: 12px;
          border: 1px solid #888;
          border-radius: 2px;
        }
      }
      &:checked ~ label {
        color: green;
        &::after {
          content: '✓';
          position: absolute;
          top: 2px;
          left: 0;
          color: #109641;
          font-weight: 600;
          line-height: 1;
        }
      }
    }

    & input[type = radio] {
      & ~ label {
        &::before {
          left: 0;
          top: 4px;
          width: 14px;
          height: 14px;
          border: 1px solid #888;
          border-radius: 50%;
        }
      }
      &:checked ~ label {
        color: green;
        &::after {
          content: '';
          position: absolute;
          top: 9px;
          left: 5px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #5694e1;
        }
      }
    }
    & > p {
      font-size: 18px;
      margin-bottom: 14px;
    }
  }
  &__footer {
    margin-top: auto;
    background-color: #dae6e6;
    border-radius: 0 0 4px 4px;
    @media (min-width: 769px) {
      padding: 30px;
    }
    @media (max-width: 768px) {
      padding: 10px;
    }
    &--btn {
      display: inline-block;
      font-size: 16px;
      padding: 10px 40px;
      border-radius: 4px;
      background-color: #65b953;
      color: white;
      font-weight: 600;
      &:hover {
        color: white;
        background-color: #65b953+20;
      }
      &.second {
        margin-right: 20px;
        @media (max-width: 768px) {
          margin-bottom: 10px;
        }
      }
    }
  }
  &__your-answer {
    display: flex;
    margin-bottom: 10px;
    &--indicator {
      margin-right: 10px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      color: white;
      font-size: 12px;
      font-weight: 400;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 10px;
        height: 2px;
        background-color: white;
      }
      &.dg-right {
        background-color: #33aa22;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 2px;
          height: 10px;
          background-color: white;
        }
      }
      &.dg-wrong {
        background-color: #fd0002;
      }
    }
    &--title {
      font-size: 18px;
      font-weight: 600;
    }
    &--text {
      display: flex;
      & .dg-letter {
        margin-right: 10px;
      }
    }
  }
}

.dg-underlining {
  display: inline-block;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 4px;
  }
  &.dashed::after {
    background: url('#{$path-img-main}/system/text-decoration/dashed.png') repeat-x;
  }
  &.dash-dot::after {
    background: url('#{$path-img-main}/system/text-decoration/dash-dot.png') repeat-x;
  }
  &.double-line::after {
    background: url('#{$path-img-main}/system/text-decoration/double-line.png') repeat-x;
  }
  &.subline::after {
    background: url('#{$path-img-main}/system/text-decoration/subline.png') repeat-x;
  }
  &.wave::after {
    background: url('#{$path-img-main}/system/text-decoration/wave.png') repeat-x;
  }
}
