@import "vendor/_normalize";
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&amp;subset=cyrillic';
@import 'test-passing';

html {
  font-family: 'Open Sans', sans-serif;
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
  font-size: 14px;
}

body {
  background: url("../img/videouroki-bg.png");
}
.container {
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  h1 {
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 30px;
    text-align: center;
  }
  &::before,
  &::after {
    content: '';
    display: table;
  }
  &::after {
    clear: both;
  }
}

.dg-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 30px;
  @media (max-width: 600px) {
    padding: 30px 10px 10px;
    min-height: 90vh;
  }
}

.v-logo {
  display: block;
  margin: 0 auto 30px;
  width: 300px;
  height: 50px;
  background: url("../img/logo.svg")no-repeat;
}

.v-content-main-task {
  background-color: #fff;
  &:after {
    content: "";
    display: block;
    clear: both;
  }
  .v-pupil-block-title {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 8px;
  }
}
.v-sum-oplata-wrapper {
  text-align: center;
  .v-sum-oplata-page {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    margin: 0px 0 50px 0;
    color: #248abc;
    .v-sum {
      text-align: center;
      position: relative;
      display: inline-block;
      font-size: 30px;
      margin-top: 0;
      line-height: 36px;
    }
  }
  .v-grey-block {
    border: 2px solid #ddd;
    background: #F5F5F5;
    min-height: 248px;
    cursor: default;
    border-radius: 6px;
    .v-main-button {
      max-height: 64px;
      i {
        width: 35px;
        height: 28px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
      &.v-blue-main {
        i {
          width: 28px;
          height: 32px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 8px;
        }
      }
    }
    .v-blue-main {
      float: none;
    }
  }
  .blue-btn{
    padding: 10px 15px;
    background: #0E6691;
    color: #fff;
    border-radius: 4px;
    font-weight: 900;
    line-height: 16px;
    transition: all 200ms linear;
    display: inline-block;
    text-align: center;
    margin: 10px 0;
    font-size: 12px;
    &:hover {
      background: #248abc;
      text-decoration: none;
    }
  }
  .v-grey-btn {
    background: #dddddd;
    color: #818181;
    &:hover {
      background: #cbc8c8;
      text-decoration: none;
    }
  }
  span {
    font-size: 18px;
    margin-top: 10px;
    display: block;
    line-height: 22px;
  }
  h1 {
    text-align: left;
  }
}
.col-6 {
  width: 49.15254%;
  float: left;
  margin-right: 1.69492%;
  &.col-last {
    float: right;
    margin-right: 0;
  }
}
.v-main-button {
  font-weight: bold;
  color: #ffffff;
  padding: 18px 26px;
  line-height: 100%;
  border-radius: 6px;
  text-align: center;
  background: #5cbb73 repeat;
  background-size: 304px;
  font-size: 22px;
  margin: 35px auto;
  display: block;
  max-width: 320px;
  position: relative;
  z-index: 999;
  &:hover {
    -webkit-animation: cssProgressActive 2s linear infinite;
    -ms-animation: cssProgressActive 2s linear infinite;
    animation: cssProgressActive 2s linear infinite;
    text-decoration: none;
    color: #ffffff;
  }
  &.load-button {
    -webkit-animation: cssProgressActive 2s linear infinite;
    -ms-animation: cssProgressActive 2s linear infinite;
    animation: cssProgressActive 2s linear infinite;
    text-decoration: none;
    color: #ffffff;
  }
  &.v-blue-main {
    background-color: #248abc;;
    float: left;
  }
  &.v-orange-main {
    background-color: #ffbf00;
  }
  &.v-next {
    float: right;
  }
  &.v-m-b {
    margin-bottom: 0;
    margin-top: 20px;
  }
  &.red-button {
    background-color: #ea3c3c !important;
  }
  &.button-task {
    margin: 18px 0 0 0;
    background: #fff;
    color: #008684;
    text-align: left;
    padding: 10px 60px 10px 19px;
    font-size: 18px;
    display: inline-block;
    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 26px;
      height: 2px;
      background-color: #008684;
      right: 21px;
      top: 29px;
      -moz-transition: all 0.6s ease-out;
      -o-transition: all 0.6s ease-out;
      -webkit-transition: all 0.6s ease-out;
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      border: 6px solid transparent;
      border-left: 7px solid #008684;
      right: 34px;
      width: 0px;
      top: 24px;
      -moz-transition: all 0.6s ease-out;
      -o-transition: all 0.6s ease-out;
      -webkit-transition: all 0.6s ease-out;
    }
    &:hover {
      &:after {
        right: 10px;
      }
    }
  }
  &.back-button {
    padding: 18px 24px 18px 56px;
    display: inline-block;
    max-width: inherit;
    margin: 0px auto 0px auto;
    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 26px;
      height: 2px;
      background-color: #fff;
      left: 21px;
      top: 29px;
      -moz-transition: all 0.6s ease-out;
      -o-transition: all 0.6s ease-out;
      -webkit-transition: all 0.6s ease-out;
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      border: 6px solid transparent;
      border-right: 7px solid #fff;
      right: 0;
      left: 35px;
      width: 0px;
      top: 24px;
      -moz-transition: all 0.6s ease-out;
      -o-transition: all 0.6s ease-out;
      -webkit-transition: all 0.6s ease-out;
    }
    &:hover {
      &:after {
        left: 12px;
      }
    }
  }
  &.v-transpar {
    background: transparent;
    color: #248abc;
    border: 1px solid #fff;
    &:before {
      background: #248abc;
    }
    &.t-left {
      &:after{
        border-right: 7px solid #248abc;
      }
    }
    &.t-right {
      padding-right: 75px;
      &:before {
        position: absolute;
        display: block;
        content: '';
        width: 26px;
        height: 2px;
        background-color: #248abc;
        right: 37px;
        top: 29px;
        -moz-transition: all 0.6s ease-out;
        -o-transition: all 0.6s ease-out;
        -webkit-transition: all 0.6s ease-out;
      }
      &:after {
        position: absolute;
        display: block;
        content: '';
        border: 6px solid transparent;
        border-left: 7px solid #248abc;
        right: 50px;
        width: 0px;
        top: 24px;
        -moz-transition: all 0.6s ease-out;
        -o-transition: all 0.6s ease-out;
        -webkit-transition: all 0.6s ease-out;
      }
      &:hover {
        &:after {
          right: 25px;
        }
      }
    }
    &:hover {
      border: 1px solid #248abc;
    }
  }
  &.v-fln {
    float: none;
  }
}
.col-1 {
  max-width: 768px;
  margin: auto;
}
.col-2 {
  float: right;
  width: 21%;
}
.v-right-block {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 20px 0 0 0;
  text-align: center;
  font-size: 16px;
  p {
    padding: 0 20px;
    margin: 10px 0;
  }
  .pay-block {
    background-color: #e2f8ff;
    margin-top: 20px;
    padding: 20px;
    font-size: 22px;
    .pay-count {
      border: 2px solid #5cbb73;
      border-radius: 6px;
      padding: 10px;
      text-transform: uppercase;
      font-weight: bold;
      color: #5cbb73;
      font-size: 26px;
      margin-top: 20px;
    }
  }
}
.v-form-code {
  label {
    font-size: 18px;
    display: block;
  }
  input[type="text"] {
    border: 1px solid #ccc;
    min-height: 58px;
    padding-left: 10px;
    font-size: 22px;
    width: 100%;
    border-radius: 6px;
    margin: 20px 0;
    outline: none;
  }
  input[type="submit"] {
    font-weight: bold;
    color: #ffffff;
    padding: 18px 26px;
    line-height: 100%;
    border-radius: 6px;
    text-align: center;
    background: #5cbb73;
    font-size: 22px;
    display: block;
    max-width: 320px;
    position: relative;
    z-index: 999;
    border: none;
    outline: none;
  }
}

.v-pupils-list-text {
  @media (max-width: 600px) {
    margin-bottom: 10px;
  }
}

.v-pupils-list-wrap {
  margin: auto;
  ul {
    li {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc;
      @media (max-width: 600px) {
        flex-direction: column;
      }
      .icon {
        display: table-cell;
        vertical-align: middle;
      }
      .icon {
        input[type=checkbox] {
          display: none;
        }
        label {
          width: 40px;
          height: 40px;
          display: inline-block;
          vertical-align: middle;
          &:before {
            content: "";
            display: block;
            position: absolute;
            cursor:pointer;
            border: 2px solid #5cbb73;
            width: 40px;
            height: 40px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
          }
        }
        input[type=checkbox]:checked + label:before {
          background: url("../img/tick.svg")no-repeat;
          background-size: 90% 90%;
          background-position: 50%;
        }
      }
      .v-pupils-sum {
        color: #5cbb73;
        font-weight: bold;
        font-size: 26px;
        text-align: right;
      }
      .v-pupils-list-text {
        .v-predmet {
          font-size: 20px;
        }
        .v-teacher {
          font-size: 18px;
          font-style: italic;
          color: #55595b;
        }
      }
      .v-pupils-list-right {
        text-align: right;
        font-size: 16px;
        .v-contest {
          margin-right: 20px;
          &.i-contest-tested-red {
            color: #df868f;
          }
          &.i-contest-tested-green {
            color: #72bb4c;
          }
          &:last-child {
            margin-right: 0;
          }
        }
        .v-contest-button {
          color: #378cb9;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
.v-pupil-name {
  text-align: center;
  font-size: 18px;
  margin: 20px 0;
  b {
    font-weight: 300;
    color: #797979;
  }
  strong {
    color: #b91414;
  }
  a {
    text-decoration: underline;
    color: #315CAB;
    &:hover {
      text-decoration: none;
    }
  }
}
.v-pupil-warning {
  padding: 10px 30px;
  background-color: #fcffe7;
  border: 1px solid #f1db7e;
  position: relative;
  margin-top: 15px;
  p {
    font-size: 16px;
    text-align: left;
  }
  span {
    font-weight: 600;
  }
}
.a-alert-block {
  background: #FCFFE7;
  border: 1px solid #F1DB7E;
  clear: both;
  padding: 10px;
  border-radius: 4px;
  max-width: 800px;
  margin: 16px auto;
}
.v-contact-link {
  margin-top: auto;
  text-align: center;
  font-size: 18px;
  a {
    color: #5cbb73;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

.help-block {
  margin: 0 0 20px;
  color: red;
}
//Модальное окно
.a-di-non-upss{
  position: fixed;
  background: #555;
  background: rgba(0, 0, 0, 0.80);
  top: 30%;
  left: 0;
  right: 0;
  margin: 0 auto 10px;
  z-index: 1001;
  width: 100%;
  max-width: 90%;
  color: #fff;
  padding: 20px;
  height: auto;
  border-radius:4px;
  -moz-box-shadow: 0 0 15px -5px #eee;
  -webkit-box-shadow: 0 0 15px -5px #eee;
  box-shadow: 0 0 15px -5px #eee;
  line-height: 1.25;

  a{
    color: #AAD2E6;
    font-weight: 900;
  }
  .kr-green {
    color: #ffffff;
    margin-top: 8px;
  }
  .a-dell{
    float: right;
    font-weight: 900;
    border-radius:4px;
    padding: 1px 8px 3px 8px;
    margin-left: 6px;
    cursor: pointer;
    &:hover{
      background: #7C7C7C;
    }
  }
}
.a-di-vind {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #555;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
}
.a-di-non-vind {
  margin: 0 auto;
  z-index: 1001;
  width: 100%;
  max-width: 100%;
  height: auto;
  background-color: #fff;
  @media (min-width: 769px) {
    margin-top: 150px !important;
    border-radius: 6px;
  }
  @media (max-width: 768px) {
    height: 100vh;
    overflow: auto;
  }
}

.kr-modal-close{
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer;
    &::before,
    &::after {
      background-color: red;
    }
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 1px;
    background-color: #333;
  }
  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
}

.kr-modal-content{
  &.kr-modal-default{
    padding: 30px;
    position: relative;
    background: #fff;
    border-radius: 4px;
    .kr-modal-title{
      font-size: 26px;
      text-align: center;
      font-weight: 700;
      margin: 0 0 20px;
      padding-bottom: 28px;
      line-height: 1.2;
    }
    &.v-modal-test-pupil {
      padding-top: 0;
      .v-modal-test-pupil-head {
        border-bottom: 1px solid #ccc;
        padding: 30px 0 20px;
        margin-bottom: 20px;
        .v-test-question__title {
          max-width: 81%;
          .kr-test-question__title {
            margin-bottom: 0;
          }
        }
        .v-clock {
          position: relative;
          top: -2px;
        }
      }
      .kr-test-question__body {
        margin-bottom: 20px;
      }
    }
  }

  &.kr-modal-test-constructor{
    @extend .kr-modal-default;

    .kr-modal-head{
      text-align: center;
    }

    .kr-modal-title{
      display: inline-block;
      margin-bottom: 30px;
      border-bottom: 1px solid #ddd;
    }

    .kr-modal-description{
      font-size: 14px;
      color: #8e8e8e;
    }
  }

  &.kr-modal__preview-question{
    @extend .kr-modal-default;
    padding-top: 0;
    padding-bottom: 0;

    .kr-modal-close{
      top: 14px;
    }

    .kr-modal-head{
      margin: 0 -50px;
      padding: 10px 50px;
    }

    .kr-modal-title{
      margin-bottom: 0;
      font-size: 20px;
      font-weight: 100;
    }

    .kr-modal__test-action{
      padding:10px 0;


      .kr-right-block{
        text-align: right;
      }
    }

    .kr-modal-body{
      padding: 20px 0;
      min-height: 206px;
    }

    .kr-modal-footer{
      @extend .kr-modal-head;
      padding-top:20px;
      padding-bottom:20px;
      text-align: center;

      .kr-inline-list{
        li{
          margin-right: 0;

          a{
            width: 24px;
            height: 24px;
            text-align: center;
            display: table-cell;
            vertical-align: middle;
            border: 1px solid rgba(255, 255, 255, 0);
          }

          &.active{
            a{
              border: 1px solid #555;
            }
          }
        }
      }
    }
  }

  &.kr-modal__gallery{
    @extend .kr-modal-default;
    padding-top: 60px;

    .kr-modal-close{
      .icon-close--red{
        opacity:.5;
      }

      &:hover{
        .icon-close--red{
          opacity:1;
        }
      }
    }

  }
  ol {
    list-style-position: inside;
  }
}
.kr-button {
  position: relative;
  text-align: center;
  min-width: 140px;
  color: #fff;
  zoom: 1;
  transition: .2s ease-out;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 2px;
  display: inline-block;
  cursor: pointer;
  &.kr-green {
    background-color: #72BB4C;
  }
  &.kr-red {
    background-color: #ff4e52;
    color: #ffffff;
  }
}
.v-align-link {
  text-align: center;
}
.kr-loader{
  padding: 20px;
  background: #fff;
  text-align: center;
  border-radius: 6px;
}
.i-question-loading {
  z-index: 999;
  background: #fff;
  border-radius: 6px;
}
.kr-radio-block{
  input{
    display: none;

    + label{
      &:after{
        display: none;
      }
    }
  }

  label {
    padding-top: 0;
    padding-left: 20px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    cursor: pointer;

    &:before{
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      width: 12px;
      height: 12px;
      margin: auto;
      border: 1px solid #888;
      border-radius: 50%;
    }

    &:after{
      content: '';
      position: absolute;
      top: 7px;
      left: 4px;
      bottom: 0;
      width: 6px;
      height: 6px;
      margin: 0 auto;
      border-radius: 50%;
      background: #888;
    }
  }

  input:checked {
    + label{
      &:after{
        display: block;
      }
    }
  }
}

.kr-checkbox-block{
  input{
    display: none;

    + label{
      &:after{
        display: none;
      }
    }
  }



  label {
    padding-top: 0;
    padding-left: 20px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      bottom: 0;
      width: 14px;
      height: 14px;
      border: 1px solid #ddd;
      background: #fff;
      border-radius: 4px;
    }

    &:after {
      content: '✔';
      position: absolute;
      left: 3px;
      top: 10px;
      bottom: 0;
      height: 0px;
      line-height: 0;
      font-size: 12px;
      color: #888;
    }
  }

  input:checked {
    + label{
      &:after{
        display: block;
      }
    }
  }
}
.icon-close {
  font-style: normal;
  font-size: 20px;
  font-family: cursive;
  line-height: 1;
}
.pull-right {
  float: right;
}
.kr-test-question__title{
  margin-bottom: 14px;

  p{
    font-size: 18px;
    margin-left:0;
  }
}
.a-di-non-upss-r{
  position: fixed;
  background: #dd3e31;
  background: rgba(165, 18, 6, 0.85);
  top: 30%;
  left: 50%;
  margin: 0 0 10px -250px;
  z-index: 1001;
  width: 100%;
  color: #fff;
  padding: 20px;
  height: auto;
  border-radius:4px;


  .errorSummary{
    color: #fff;
  }
  a{
    color: #fff;
    text-decoration: underline;
    font-weight: 900;
  }
  .a-dell{
    float: right;
    font-weight: 900;
    border-radius:4px;
    padding: 1px 8px 3px 8px;
    margin-left: 7px;
    cursor: pointer;
    &:hover{
      background: #892D25;
    }
  }
}
.v-test-result-item {
  p {
    display: inline-block;
    vertical-align: top;
  }
}

.wrap {
  margin-bottom: 30px;
}

.dg-awards {
  &__title {
    display: block;
    margin-bottom: 10px;
    font-weight: 600;
  }
  &__wrapper {
    display: flex;
    justify-content: space-between;
    @media (max-width: 990px) {
      flex-direction: column;
    }
    & a {
      width: 24%;
      margin-bottom: 10px;
      padding: 6px 8px 6px 30px;
      border-radius: 4px;
      background-image: linear-gradient(to right top, #359093, #28a890);
      position: relative;
      color: white;
      &:hover {
        background-image: linear-gradient(to right top, #359093+20, #28a890+20);
        color: white;
      }
      @media (max-width: 990px) {
        width: 100%;
      }
      &:before {
        content: '';
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 12px;
        height: 12px;
        background: url('../img/download.svg') no-repeat;
        background-size: contain;
      }
    }
  }
}